/* General Resets and Typography */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body, html {
  height: 100%;
  margin: 0;
  font-family: 'Helvetica Neue', Arial, sans-serif;
}
.App {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: #eaeaea;
  color: #333;
}

/* Header and Main Content Styles */
.App-header {
  width: 100%;
  max-width: 600px;
  background: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 10px 25px rgba(0,0,0,0.1);
  text-align: center;
}

/* Button Styles */
button {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  padding: 12px 20px;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
button:hover {
  background-color: #0056b3;
}

/* Form and Input Styles */
input {
  border: 2px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  width: 100%;
  font-size: 16px;
}
ul {
  list-style-type: none;
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  position: absolute;
  width: calc(100% - 20px);
  max-height: 200px;
  overflow-y: auto;
}
li {
  padding: 10px;
  cursor: pointer;
}
li:hover {
  background-color: #f0f0f0;
}

/* Game Tile and Grid */
.game-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  margin-top: 20px;
}
.GameTile {
  background: #f9f9f9;
  border: 2px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  transition: transform 0.2s ease-in-out;
}
.GameTile:hover {
  transform: translateY(-5px);
}
.GameTile img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 5px;
}
.GameTile p {
  margin-top: 5px;
  color: #666;
}
.bg-green-500 {
  background-color: #4CAF50;
}
.bg-red-500 {
  background-color: #f44336;
}

/* Score Display */
.score {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  font-size: 24px;
}
